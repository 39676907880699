<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="代理任务"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="10">

            <el-col :span="4">
              <div class="search_item">
                <div>任务ID：</div>
                <el-input v-model="searchData.taskNo"></el-input>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="search_item">
                <div>任务名称：</div>
                <el-input v-model="searchData.taskName"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="4" :offset="20">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <router-link to="/insurance/insuranceAgency/add">
            <el-button type="primary">新建任务</el-button>
          </router-link>
        </div>
      </template>
      <el-table-column label="任务ID" fixed="left" prop="taskNo" :min-width="120"></el-table-column>
      <el-table-column label="任务名" prop="taskName" :min-width="100"></el-table-column>
      <el-table-column label="说明" prop="taskDesc" :min-width="150"></el-table-column>
      <el-table-column label="状态" prop="status" :min-width="100">
        <template slot-scope="scope">
          <el-switch
            @change="(e)=>changeState(e,scope.row)"
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="ctime" :min-width="180"></el-table-column>
      <el-table-column label="更新时间" prop="mtime" :min-width="180"></el-table-column>
      <el-table-column label="最近操作人" prop="updatorName" :min-width="120"></el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="150">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(2, scope.row)">查看</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        listData: [],
        // 搜索数据
        searchData: {
          taskNo: "", //任务编号
          taskName: "",             //任务名称
        },
        listLoading: false, //列表loading
      };
    },
  filters: {
    stateFilter: function(val) {
      
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getAgencyList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 表格内操作
    tableHandle(type, row) {
      console.log("type:" + type + " row:" + JSON.stringify(row));
      switch (type) {
        case 1:     //编辑
          this.$router.push(`/insurance/insuranceAgency/edit?taskNo=${row.taskNo}`);
          break;
        case 2:     //查看
          this.$router.push(`/insurance/insuranceAgency/detail?taskNo=${row.taskNo}`);
          break;
        default:
          break;
      }
    },

    // 更改状态
    changeState(e,row){
      this.$store.dispatch('insurance/agentTaskChangeStatus',{
        taskNo: row.taskNo,
        status: e,
      }).then(res => {
        this.$refs.list.init();
      })
    },


    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        taskNo: "", //任务编号
        taskName: "",             //任务名称
      }
    },


    // 获取代理列表
    getAgencyList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('insurance/getAgentTaskList',{
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      .el-button{
        margin-right: 10px;
      }
      i{
        color: #fff;
      }
    }
    .table_row_rule{
      word-break: break-all; /** 换行，长单词会分成两行显示 **/
      text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 1; /** 显示的行数 **/
      overflow: hidden;  /** 隐藏超出的内容 **/;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  .search_item{
    display: flex;
    align-items: center;
    >div:first-child{
      flex-shrink: 0;
    }
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
